@use "sass:map";
@import './settings';

.page-title,
.section-title,
.section-title2,
.section-title3,
.section-title4 {
  font-size: unit-calc(36px);
  color: map.get($themeColors, 'buttonBg');
  font-weight: 300;
  margin-bottom: 0;
  text-transform: capitalize;
  &.noTransform {
    text-transform: none;
  }
}
.section-title {
  font-size: unit-calc(26px);
}
.section-title2 {
  font-size: unit-calc(22px);
}
.section-title3 {
  font-size: unit-calc(24px);
}
.section-title4 {
  font-size: unit-calc(14px);
}
.pageTitle-desc {
  font-size: unit-calc(18px);
}
.fs-10 {
  font-size: unit-calc(10px);
}
.fs-12 {
  font-size: unit-calc(12px);
}
.fs-18 {
  font-size: unit-calc(18px)  !important;
}
.fs-26 {
  font-size: unit-calc(26px);
}
.fs-16 {
  font-size: unit-calc(16px) !important;
}
.fs-14 {
  font-size: unit-calc(14px) !important;
}
.fs-13 {
  font-size: unit-calc(13px)  !important;
}
.fs-12 {
  font-size: unit-calc(12px)  !important;
}
.fs-11 {
  font-size: unit-calc(11px);
}
.fs-20 {
  font-size: unit-calc(20px);
}
.fs-22 {
  font-size: unit-calc(22px);
}
.fs-24 {
  font-size: unit-calc(24px);
}
.pt-20 {
  padding-top: unit-calc(20px);
}
.w-0 {
  width: 0;
}
.mb-50 {
  margin-bottom: unit-calc(50px);
}
.section-title-description {
  font-size: unit-calc(16px);
  @include font-weight('normal');
}
.opacity-0 {
  opacity: 0;
}
