.table-container {
  overflow-x: auto;

  &.fixedHeader {
    max-height: calc(100vh - 260px);
    border: 1px solid map.get($themeColors, 'coolGrey6');
  }

  &.noBorder {
    border: none;
  }

  &.singleRow,
  &.emptyRow {
    min-height: unit-calc(250px) !important;
    border: 0;

    table {
      border: 1px solid map.get($themeColors, 'coolGrey6');
    }
  }

  table {
    width: 100%;

    th.mat-mdc-header-cell:first-of-type,
    td.mat-mdc-cell:first-of-type,
    td.mat-mdc-footer-cell:first-of-type {
      padding-left: unit-calc(24px);
    }

    td.highlightCell {
      background: map.get($themeColors, 'green101');
    }

    th.mat-mdc-header-cell {
      font-size: unit-calc(14px);
      color: map.get($themeColors, grey63) !important;
      padding: unit-calc(5px);
      border-right: unit-calc(1px) solid map.get($themeColors, 'coolGrey6');
      background: map.get($themeColors, 'greyBgColor');
      line-height: unit-calc(20px);
      overflow: inherit;
      @include font-weight('normal');

      &:last-child {
        border-right: 0;
      }

      &.text-right {
        text-align: right;
      }

      strong {
        font-size: unit-calc(16);
        @include font-weight('normal');
      }

      .filterIcon {
        position: absolute;
        top: unit-calc(20);
        right: unit-calc(7);
        @include cursor;
        width: unit-calc(20px);
        height: unit-calc(20px);
        background: url('../images/icons/icon_filter.svg') no-repeat 0 0;
      }

      .filter-applied {
        background-image: url('../images/icons/icon_fliter_green.svg');
      }

      .mat-sort-header-container {
        color: map.get($themeColors, 'grey63');
        width: 85%;
        display: flex;
        justify-content: space-between;
      }

      &.noBorder .mat-sort-header-container {
        border: none;
      }

      &.noFilter .mat-sort-header-container {
        width: 100%;
      }

      &.width250 {
        width: unit-calc(250);
      }

      &.width200 {
        width: unit-calc(200);
      }

      &.width50 {
        width: unit-calc(50);
      }

      &.width150 {
        width: unit-calc(150);
      }

      &.width100 {
        width: unit-calc(100);
      }
    }

    .mat-mdc-row {
      border-bottom: unit-calc(1px) solid map.get($themeColors, 'coolGrey6');
    }

    .mat-mdc-cell {
      word-break: break-word;
      padding: unit-calc(0.55rem);
      vertical-align: top;
      max-width: unit-calc(100px);
      border-right: unit-calc(1px) solid map.get($themeColors, 'coolGrey6');

      &:last-child {
        border-right: 0;
      }

      &.mat-column-Action {
        padding-top: unit-calc(7);
        text-align: center;
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}