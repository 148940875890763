@use 'sass:map';
@import './settings';

button,
a.button {
  text-decoration: none;
  border: 0;
  text-transform: none !important;
  text-align: center;
  border-radius: unit-calc(4px);
  font-size: $baseFontSize;
  padding: unit-calc(10px 30px);
  background: map.get($themeColors, 'buttonBg');
  color: map.get($themeColors, 'white');
  outline: none !important;
  white-space: nowrap;
  font-family: UniversForFiserv55 !important;
  &.menu-link-btn {
    margin: unit-calc(0 0 0 25px);
    padding: unit-calc(5px);
    background: map.get($themeColors, 'white');
    color: map.get($themeColors, 'buttonBg');
    @include font-weight('normal');
    font-size: unit-calc(13px);
  }
  &.edit-btn {
    background: transparent;
    color: map.get($themeColors, 'buttonBg') !important;
    border: unit-calc(1px) solid map.get($themeColors, 'buttonBg');
    &:disabled {
      border-color: map.get($themeColors, 'coolGrey8');
      color: map.get($themeColors, 'coolGrey8') !important;
    }
  }

  &.btn-icon {
    background: transparent;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    &:not(:last-child) {
      margin-right: unit-calc(10px);
    }
    img {
      width: unit-calc(18);
      height: unit-calc(18);
      &.x-large {
        width: unit-calc(30);
        height: unit-calc(30);
      }
    }
    &.toasterClose img {
      width: unit-calc(24);
      height: unit-calc(24);
    }
  }
  &.mat-mdc-menu-item {
    margin: 0;
    border-bottom: 0;
    color: map.get($themeColors, 'black');
    border-radius: 0;
    height: unit-calc(40px);
    line-height: unit-calc(40px);
    @include font-weight('light');
    &.selectedLanguage {
      background-color: map.get($themeColors, 'buttonBg');
      color: map.get($themeColors, 'white');
      &:hover {
        background-color: map.get($themeColors, 'buttonBg');
        color: map.get($themeColors, 'white');
      }
    }
  }

  &.mat-mdc-menu-trigger {
    background-color: transparent;
    font-size: unit-calc(14px);
  }
  &.btn-link,
  &.btn-link:hover {
    color: map.get($themeColors, 'grey63');
    text-decoration: none;
    background-color: transparent;
  }
  &.normal {
    font-family: 'UniversForFiserv45' !important;
  }
  &.btn-link-primary,
  &.cancel-button,
  &.btn-link-primary:hover {
    color: map.get($themeColors, 'buttonBg');
    background-color: transparent;
    text-decoration: underline;
  }
  &.cancel-button {
    margin: unit-calc(0 12px);
    &:hover {
      background: map.get($themeColors, 'grey20');
    }
  }
  &.outline-primary,
  &.outline-primary:hover {
    color: map.get($themeColors, 'buttonBg');
    border: unit-calc(1px) solid map.get($themeColors, 'buttonBg');
    background: transparent;
  }
  &.outline-primary:hover {
    background: map.get($themeColors, 'green96');
  }
  &.outline-blue {
    color: map.get($themeColors, 'blue1');
    border: unit-calc(1px) solid map.get($themeColors, 'blue1');
    background: transparent;
  }
  &.small-btn {
    padding: unit-calc(5px 16px);
    font-size: unit-calc(13);
  }
  &.secondary {
    background: map.get($themeColors, 'white');
    color: map.get($themeColors, 'grey63');
    border: unit-calc(1px) solid map.get($themeColors, 'buttonBg');
  }
  &.pageNo {
    background: transparent;
    color: map.get($themeColors, 'primaryColor') !important;
    padding: 0;
    border: none;
  }
  &.disabled:not(.mat-mdc-icon-button) {
    background: map.get($themeColors, 'grey15');
    color: map.get($themeColors, 'grey45');
    border: unit-calc(1px) solid map.get($themeColors, 'grey15');
    cursor: default;
    &.btn-link-primary {
      background: transparent;
      border-color: transparent;
    }
  }
}
