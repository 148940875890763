@use "sass:map";
@import './settings';

.dialog-container {
  padding: unit-calc(20px);
  overflow-y: auto;
  max-height: 70vh;
  .dialog-title {
    margin: 0;
    @include font-weight('regular');
    color: map.get($themeColors, grey63);
    display: flex;
    align-items: center;
    line-height: unit-calc(32px);
    padding: 0;
    @media screen and (max-width: $bpSmall) {
      font-size: unit-calc(18px);
    }
  }
  .dialog-header {
    display: flex;
    justify-content: space-between;
    padding: 0;
    .dialog-header-btn {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .dialog-footer {
    display: flex;
    justify-content: flex-end;
  }
  .dialog-content {
    padding: unit-calc(20px 0);
    .dialog-description {
      font-size: unit-calc(18px);
      font-weight: 300;
      line-height: unit-calc(24px);
    }
    .column-header {
      display: flex;
      font-size: unit-calc(20px);
      font-weight: 300;
      margin: unit-calc(10px) 0;
      padding: unit-calc(10px) 0;
      div {
        width: 50%;
      }
    }
    .column-container {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin: unit-calc(10px) 0;
      .column-name {
        width: 30%;
        font-size: unit-calc(16px);
        font-weight: 300;
        border-bottom: 1px solid map.get($themeColors, 'grey7');
        padding-bottom: unit-calc(10px);
      }
      .column-img {
        top: unit-calc(5px);
        position: relative;
      }
      .column-input {
        margin: 0 !important;
        width: 50% !important;
      }
    }
  }
}

.call-container .mat-mdc-dialog-container {
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    background: transparent;
    box-shadow: none;
    height: initial;
  }
}

.call-bc-search-container .mat-mdc-dialog-container {
  border-radius: unit-calc(10px);
  background: map.get($themeColors, 'grey202');   
  box-shadow: unit-calc(0px 4px 4px)map.get($themeColors, 'greyccc');
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    background: transparent;
    box-shadow: none;
    height: initial;
  }
}