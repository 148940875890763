.mat-mdc-paginator-outer-container .mat-mdc-paginator-container {
  justify-content: space-between;
  padding: 0;
  flex-flow: row-reverse;
  .mat-mdc-paginator-page-size {
    margin-right: 0;
  }
  .mat-mdc-paginator-range-actions {
    .mat-mdc-paginator-range-label {
      margin: 0;
    }
  }
}

.mat-mdc-unelevated-button {
  color: map.get($themeColors, 'buttonBg');
  border-bottom: 0;
  margin: 1%;
  &.pageNo {
    padding: 0;
    min-width: auto;
    border: 0;
    background-color: transparent;
    box-shadow: none !important;
    border-radius: none !important;
  }
}