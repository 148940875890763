@use 'sass:map';
@import './settings';

.mat-datepicker-popup {
  .mat-calendar-body-range-start:not(
      .mat-calendar-body-in-comparison-range
    )::before,
  .mat-calendar-body-range-start::after,
  .mat-calendar-body-comparison-start:not(
      .mat-calendar-body-comparison-bridge-start
    )::before,
  .mat-calendar-body-comparison-start::after,
  .mat-calendar-body-preview-start .mat-calendar-body-cell-preview {
    width: 62% !important;
  }

  .mat-calendar-body-cell::before,
  .mat-calendar-body-cell::after,
  .mat-calendar-body-cell-preview {
    width: 67% !important;
  }

  .mat-calendar-body-in-range:before {
    background-color: rgba(66, 172, 42, 0.3);
  }

  .mat-datepicker-actions {
    justify-content: space-between !important;
    .mat-mdc-raised-button:not(:disabled) {
      background-color: map.get($themeColors, 'primaryColor') !important;
    }
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    background-color: rgba(66, 172, 42, 0.5) !important;
    color: map.get($themeColors, 'white') !important;
  }
  .mat-calendar-body-disabled
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    color: map.get($themeColors, 'grey204') !important;
  }

  .mat-calendar-body-cell-content,
  .mat-date-range-input-separator {
    color: map.get($themeColors, 'grey63') !important;
    font-size: unit-calc(14px);
  }

  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    border-color: rgba(map.get($themeColors, 'primaryColor'), 0.6);
    background-color: rgba(
      map.get($themeColors, 'primaryColor'),
      0.3
    ) !important;
  }

  .mat-calendar-body-cell-content {
    width: 62% !important;
  }

  .mat-calendar-body-selected {
    background-color: map.get($themeColors, 'primaryColor') !important;
    color: map.get($themeColors, 'white') !important;
    font-size: unit-calc(14px);
  }
}
.mat-datepicker-toggle-active,
.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: map.get($themeColors, 'primaryColor');
}

.mat-mdc-select {
  .mat-mdc-select-value {
    letter-spacing: initial;
    font-size: calc(14px);
    color: map.get($themeColors, 'black');
  }
}

.mat-form-field-disabled {
  .mat-mdc-select {
    .mat-mdc-select-value {
      color: map.get($themeColors, 'grey63');
    }
  }
}

mat-card.mat-mdc-card,
div.card {
  border: 0;
  mat-card-header.mat-mdc-card-header,
  mat-card-content.mat-mdc-card-content:last-child {
    padding: 0;
  }

  color: map.get($themeColors, 'grey63');
  box-shadow: 0 0 unit-calc(7px) #0000001a !important;
  margin-bottom: unit-calc(32px);
  padding: unit-calc(24);

  &.px10 {
    padding: unit-calc(0 10px);
  }
}
div.card {
  background-color: map.get($themeColors, 'white');
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: transparent !important;
}

.mat-primary {
  mat-option {
    &.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) {
      .mdc-list-item__primary-text {
        color: map.get($themeColors, 'primaryColor');
      }

      mat-pseudo-checkbox.mat-pseudo-checkbox-minimal {
        display: none;
      }
    }
  }
}

mat-form-field.mat-mdc-form-field {
  .mat-mdc-input-element {
    font-size: unit-calc(14px);
    letter-spacing: initial;
  }

  &.cloverInput {
    width: 100%;

    &.mat-form-field-appearance-fill {
      .mat-mdc-select-arrow-wrapper {
        transform: translate(-50%);

        .mat-mdc-select-arrow {
          border: 0;
          background: url('../images/icons/icon_angle_down.svg') no-repeat 99%
            center;
          width: unit-calc(14);
          height: unit-calc(14);
          background-size: 100%;

          svg {
            display: none;
          }
        }
      }
      .mat-mdc-text-field-wrapper {
        height: unit-calc(48px);
        padding: unit-calc(0 8px);

        &.mdc-text-field--invalid mat-label + span {
          color: map.get($themeColors, 'error');
        }
      }
      .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
        .mat-mdc-form-field-infix {
        padding-top: unit-calc(20px) !important;
      }
    }

    &.textArea.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper,
    &.matChipGrid.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
      height: auto !important;
    }
    &.textArea.longHeight.mat-form-field-appearance-fill
      .mat-mdc-text-field-wrapper {
      height: unit-calc(270) !important;
      textarea {
        height: unit-calc(240);
        resize: none;
        line-height: unit-calc(24);
      }
    }
    .mat-mdc-form-field-focus-overlay {
      opacity: 0;
    }
    &.mat-mdc-form-field-type-mat-chip-grid .mat-mdc-text-field-wrapper {
      height: auto !important;
    }
    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      position: relative !important;
      top: unit-calc(-20px) !important;
    }
  }
  &.searchInput {
    width: 100%;
    .mat-mdc-form-field-focus-overlay {
      opacity: 0;
    }
    &.mat-mdc-form-field-type-mat-chip-grid .mat-mdc-text-field-wrapper {
      height: auto !important;
    }
    .mdc-text-field {
      background: none !important;
      padding: 0;
      .input-group-row  {
        display: flex;
        justify-content: baseline;
        align-items: center;
        position: relative;
        input {
          width: calc(100%);
          padding: unit-calc(12px);
          border: none;
          border-radius: unit-calc(20px);
          background-color: map-get($themeColors, 'greyBgColor');
          color: map-get($themeColors, 'inputTextColor');
        }
        .input-group-icon {
          position: relative;
          right: unit-calc(30px);
          img {
            width: unit-calc(20px);
            height: unit-calc(20px);
            cursor: pointer;
          }
        }
      }
    }
    .mdc-line-ripple {
      display: none;
    }
  }
  &.hideBottomSpace {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  &.textArea {
    .mat-mdc-text-field-wrapper {
      height: unit-calc(120px) !important;
    }

    &.shadedTextArea {
      background: rgba(map.get($themeColors, 'black'), 0.04);

      .mat-form-field-flex {
        background: transparent;
      }

      .mat-error:empty {
        display: none;
      }
    }

    .mat-mdc-form-field-flex {
      height: unit-calc(110);

      textarea {
        height: unit-calc(90);
        resize: none;
        line-height: unit-calc(24);
      }
    }

    &.longHeight {
      .mat-form-field-flex {
        height: unit-calc(180);

        textarea {
          height: unit-calc(160);
          resize: none;
          line-height: unit-calc(24);
        }
      }
    }
  }

  &.whiteBackGround {
    background: map.get($themeColors, 'white');
  }

  mat-label {
    color: map.get($themeColors, 'buttonBg');
    font-size: unit-calc(14);
    font-family: UniversForFiserv55 !important;
    & + span {
      color: map.get($themeColors, 'primaryColor');
    }
  }

  .mat-mdc-form-field-error-wrapper {
    padding: 0 0;
  }

  .angular-editor.angular-editor-wrapper.angular-editor-textarea {
    border-bottom: unit-calc(1px) solid map.get($themeColors, 'primaryColor') !important;
  }

  .mdc-text-field--disabled .mdc-line-ripple::before,
  .mdc-text-field--filled .mdc-line-ripple::after,
  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-line-ripple::before {
    border-bottom-color: map.get($themeColors, 'primaryColor');
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background: map.get($themeColors, 'fafafa');
  }

  .iconWrapper {
    position: absolute;
    background: map.get($themeColors, 'white');
    bottom: 0;
    right: 0;
    border-radius: unit-calc(4 4 0 0);
    padding: 0;
    z-index: 10;

    img {
      cursor: pointer;
      padding: unit-calc(10);

      &:not(:last-child) {
        margin-right: unit-calc(5);
      }
    }

    &.textArea {
      bottom: unit-calc(9px);
      right: unit-calc(-8px);
    }
  }

  &.custom-dropdown {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background: map.get($themeColors, 'white');
      max-height: unit-calc(32);
      .mat-mdc-form-field-focus-overlay {
        background-color: map.get($themeColors, 'white');
      }
    }

    &.custom-dropdown.nopadding {
      .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        padding: 0;
      }
    }

    .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
        .mdc-text-field--textarea
      )
      .mat-mdc-form-field-infix {
      padding: 0;
    }
    mat-select {
      .mat-mdc-select-arrow {
        color: map.get($themeColors, 'primaryColor') !important;
      }
    }
    &.mat-mdc-form-field .mdc-text-field--disabled .mdc-line-ripple:before,
    &.mat-mdc-form-field .mdc-text-field--filled .mdc-line-ripple:after,
    &.mat-mdc-form-field
      .mdc-text-field--filled:not(.mdc-text-field--disabled)
      .mdc-line-ripple:before {
      border-bottom-color: map.get($themeColors, 'primaryColor');
      border: none;
    }
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
}

.mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--selected:enabled
  .mdc-switch__icon {
  display: none;
}

.cloverTextEditor {
  margin-bottom: unit-calc(10);
  position: relative;

  .mainEditor {
    border-bottom: unit-calc(2px) solid map.get($themeColors, 'primaryColor') !important;
  }

  label {
    font-size: unit-calc(12);
    color: map.get($themeColors, 'primaryColor');
  }

  .angular-editor .angular-editor-wrapper .angular-editor-textarea,
  textarea {
    font-size: unit-calc(14px);
    min-height: unit-calc(90) !important;
    background-color: rgba(0, 0, 0, 0.04) !important;
    resize: none;
    line-height: unit-calc(24);
    margin: 0;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom: unit-calc(1px) solid map.get($themeColors, 'primaryColor');
  }

  &.longTextEditor {
    .angular-editor .angular-editor-wrapper .angular-editor-textarea {
      height: unit-calc(250) !important;
    }
  }

  .iconWrapper {
    position: absolute;
    background: map.get($themeColors, 'white');
    bottom: unit-calc(4);
    right: unit-calc(2);
    border-radius: unit-calc(4 4 0 0);
    padding: 0;
    z-index: 10;

    img {
      cursor: pointer;
      padding: unit-calc(10);

      &:not(:last-child) {
        margin-right: unit-calc(5);
      }
    }
  }
}

.mat-mdc-snack-bar-container {
  &.success .mdc-snackbar__surface {
    background: map.get($themeColors, 'primaryColor') !important;
    color: map.get($themeColors, 'white') !important;
  }
  &.error .mdc-snackbar__surface {
    background: map.get($themeColors, 'error') !important;
    color: map.get($themeColors, 'white') !important;
  }
  &.info .mdc-snackbar__surface {
    background: map.get($themeColors, 'info') !important;
    color: map.get($themeColors, 'white') !important;
  }
}

.mat-mdc-slide-toggle {
  .mdc-switch__icon .mdc-switch__icon--off {
    display: none;
  }
  .mdc-switch:enabled .mdc-switch__track::after {
    background: map.get($themeColors, 'primaryColor') !important;
  }
  .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
    background: map.get($themeColors, 'white') !important;
  }
  .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
    background: map.get($themeColors, 'primaryColor') !important;
  }
  .mdc-switch__ripple,
  .mdc-switch--selected .mdc-switch__icon--on,
  .mdc-switch--unselected .mdc-switch__icon--off {
    opacity: 0;
  }
}

/*Tabset Style*/
.custom-tab {
  &.mat-mdc-tab-group {
    &.mat-primary {
      > .mat-mdc-tab-header {
        border: 0;

        > .mat-mdc-tab-label-container > .mat-mdc-tab-list .mat-ink-bar {
          top: 0;
          height: unit-calc(24px);
          border-radius: unit-calc(10px);
          z-index: -1;
          transition: none;
        }

        .mat-mdc-tab-label {
          &.mat-mdc-tab-label-active {
            flex-grow: 0;
            background: map.get($themeColors, 'white');
            border-radius: unit-calc(13px 13px 0 0);
            opacity: 1;
            border-bottom: 0;
            border-top: unit-calc(4) solid map.get($themeColors, 'primaryColor');

            &::after {
              display: none;
            }
          }
        }

        .highlight {
          width: 100%;
          height: unit-calc(47);
          z-index: -1;
          position: absolute;
          border-radius: unit-calc(12px 12px 0 0);
        }
      }
    }
  }

  > .mat-mdc-tab-nav-bar {
    border: 0;
  }
}

.mat-tab-label.mat-tab-label-active {
  border-bottom: unit-calc(2px) solid map.get($themeColors, 'primaryColor2');
  // opacity: 1;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled)
  .mdc-tab-indicator__content--underline {
  border-color: map.get($themeColors, 'primaryColor');
}

.mat-mdc-tab-group {
  .hideHeader {
    .mat-mdc-tab-header {
      display: none;
    }
  }
}

.custom-tab {
  &.mat-mdc-tab-group {
    &.mat-mdc-tab-group-stretch-tabs {
      .mat-mdc-tab-header {
        .mat-mdc-tab-label-container {
          flex-grow: 0 !important;
        }
      }
    }
  }
  &.stretch-equally {
    &.mat-mdc-tab-group {
      &.mat-mdc-tab-group-stretch-tabs {
        .mat-mdc-tab-header {
          .mat-mdc-tab-label-container {
            flex-grow: 1 !important;
          }
        }
      }
    }
  }
}
.mat-mdc-tab-group {
  &.mat-mdc-tab-group-stretch-tabs {
    .mat-mdc-tab-header {
      .mat-mdc-tab {
        //  flex-grow: 0;
        position: relative;

        &:not(:last-child) {
          &::after {
            content: '';
            border-right: unit-calc(1) solid map.get($themeColors, 'grey204');
            position: absolute;
            width: unit-calc(1);
            height: unit-calc(20);
            right: 0;
            bottom: unit-calc(12);
          }
        }
      }
    }
  }

  &.cloverTabset {
    .mat-mdc-tab-header {
      .mat-mdc-tab-label-container {
        .mat-mdc-tab-labels {
          .mat-mdc-tab {
            letter-spacing: initial;

            .mdc-tab__ripple,
            .mat-mdc-tab-ripple {
              display: none;
            }
          }
        }
      }
    }

    .mat-mdc-tab:not(.mat-mdc-tab-disabled)
      .mdc-tab-indicator__content--underline,
    .mat-mdc-tab-link:not(.mat-mdc-tab-disabled)
      .mdc-tab-indicator__content--underline {
      border-color: map.get($themeColors, 'primaryColor');
    }

    .mat-mdc-tab-body-wrapper {
      padding-top: unit-calc(16);
    }

    .mdc-tab--active {
      .mdc-tab__text-label {
        color: map.get($themeColors, 'primaryColor');
        font-size: unit-calc(16);
      }
    }
    .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
      color: map.get($themeColors, 'primaryColor');
    }
    .mat-mdc-tab-label {
      position: relative;
      min-width: initial;
      padding: 0 4%;

      &:not(:last-child) {
        &::after {
          content: '';
          height: unit-calc(20px);
          background-color: map.get($themeColors, 'grey204');
          width: unit-calc(1);
          position: absolute;
          right: 0;
        }
      }
    }

    &.p16 {
      .mat-mdc-tab-label {
        padding: unit-calc(0 16);
      }
    }

    .clover-matTab-bodyScroll {
      overflow-x: hidden;
      overflow-y: auto;
    }

    &.extra-label-padding {
      &:not(:first-child) {
        padding-left: unit-calc(48);
      }
    }

    &.withExtenedTabs {
      .mat-mdc-tab-label {
        padding: unit-calc(0 16);
      }
    }

    &.withSuperExtenedTabs {
      .mat-mdc-tab-label {
        padding: unit-calc(0 32);
      }
    }
  }

  &.custom-tab {
    &.mat-mdc-tab-group {
      &.mat-primary {
        > .mat-mdc-tab-header {
          border: 0;
          background: map.get($themeColors, 'bgGray');
          .mat-mdc-tab-label-container > .mat-mdc-tab-list {
            top: 0;
            height: unit-calc(46px);
            border-radius: unit-calc(10px);
            z-index: -1;
            transition: none;
          }

          .mat-mdc-tab {
            .mdc-tab__ripple,
            .mat-mdc-tab-ripple {
              display: none;
            }

            &.mdc-tab--active {
              //flex-grow: 0;
              background: map.get($themeColors, 'white');
              border-radius: unit-calc(13px 13px 0 0);
              opacity: 1;
              border-bottom: 0;
              border-top: unit-calc(4) solid
                map.get($themeColors, 'primaryColor');

              &::after {
                display: none;
              }
            }
          }

          .highlight {
            width: 100%;
            height: unit-calc(47);
            z-index: -1;
            position: absolute;
            border-radius: unit-calc(12px 12px 0 0);
          }
        }
      }
    }

    > .mat-tab-nav-bar {
      border: 0;
    }

    .mat-mdc-tab-body-wrapper {
      padding-top: 0;
    }
  }
}

.dialog-container .mat-mdc-dialog-content.dialog-content {
  margin: 0;
  padding: 0;
}

.mat-mdc-paginator-container {
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__trailing {
    border: none;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__trailing {
    border: none;
  }

  .mat-mdc-select-arrow svg {
    color: map.get($themeColors, 'primaryColor');
  }
}

.mat-mdc-option {
  font-size: unit-calc(13px);
}
.button.disabled:not(.mat-mdc-icon-button) {
  height: unit-calc(45px);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text,
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon
  .mdc-list-item__start {
  color: map.get($themeColors, 'primaryColor') !important;
}

mat-radio-group {
  .mat-mdc-radio-button {
    .mdc-radio
      .mdc-radio__native-control:enabled:checked
      + .mdc-radio__background
      .mdc-radio__outer-circle {
      border-color: map.get($themeColors, 'primaryColor') !important;
    }
    .mdc-radio
      .mdc-radio__native-control:enabled
      + .mdc-radio__background
      .mdc-radio__inner-circle {
      border-color: map.get($themeColors, 'primaryColor') !important;
    }

    &.mat-mdc-radio-checked .mat-ripple-element {
      background-color: map.get($themeColors, 'primaryColor');
    }

    &.mat-accent {
      --mdc-radio-selected-focus-icon-color: map.get(
        $themeColors,
        'primaryColor'
      );
      --mdc-radio-selected-hover-icon-color: map.get(
        $themeColors,
        'primaryColor'
      );
      --mdc-radio-selected-icon-color: map.get($themeColors, 'primaryColor');
      --mdc-radio-selected-pressed-icon-color: map.get(
        $themeColors,
        'primaryColor'
      );
      --mat-mdc-radio-checked-ripple-color: map.get(
        $themeColors,
        'primaryColor'
      );
    }
  }
}

.mat-mdc-checkbox {
  &.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
    background: map.get($themeColors, 'primaryColor');
  }
  &.hide-focus.mat-accent {
    .mdc-checkbox__ripple,
    .mat-ripple {
      display: none;
    }
  }
  .mdc-form-field {
    height: unit-calc(25px);
  }
}
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
    [data-indeterminate='true']
  )
  ~ .mdc-checkbox__background {
  border-color: map.get($themeColors, 'primaryColor') !important;
}

.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background {
  background: map.get($themeColors, 'primaryColor') !important;
  border-color: map.get($themeColors, 'primaryColor') !important;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: map.get($themeColors, 'primaryColor') !important;
}

.mat-mdc-form-field-error {
  display: flex !important;
}
.bottomSpaceZero {
  &.rangeFilter {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
    span[mattextprefix] {
      font-size: unit-calc(14);
    }
  }
  &.noLabel {
    .mat-mdc-select-arrow {
      color: map.get($themeColors, 'primaryColor');
    }
    &.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
      color: map.get($themeColors, 'primaryColor');
    }
  }
}
.mat-mdc-form-field-type-mat-date-range-input {
  width: 0;
  height: 0;
}

.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  font-size: unit-calc(14px);
}

.mat-mdc-option .mdc-list-item__primary-text {
  width: 100%;
  color: map.get($themeColors, 'black');
  font-size: unit-calc(14px) !important;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
  font-weight: normal;
}
.mat-expansion-panel-body {
  padding: 0px !important;
}
.mat-expansion-panel-header.mat-expanded {
  height: 43px !important;
}
.mat-accordion > .mat-expansion-panel-spacing:last-child,
.mat-accordion
  > *:last-child:not(.mat-expansion-panel)
  .mat-expansion-panel-spacing {
  margin-bottom: 0;
  border: 1px solid map.get($themeColors, 'coolGrey6') !important;
}
.mat-expansion-panel:not([class*='mat-elevation-z']) {
  box-shadow: none;
  border: 1px solid map.get($themeColors, 'coolGrey6') !important;
}
.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: map.get($themeColors, 'buttonBg') !important;
}

.mat-mdc-slider {
  .mat-ripple .mat-mdc-slider-focus-ripple,
  .mat-ripple .mat-mdc-slider-active-ripple {
    opacity: 0;
  }
  .mdc-slider__value-indicator {
    background-color: map.get($themeColors, 'buttonBg') !important;
    opacity: 1 !important;
  }
  .mdc-slider__value-indicator::before {
    border-top-color: map.get($themeColors, 'buttonBg') !important;
  }
  .mdc-slider__thumb-knob {
    border-color: map.get($themeColors, 'buttonBg') !important;
    background-color: map.get($themeColors, 'buttonBg') !important;
  }
  .mdc-slider__track--inactive {
    background-color: map.get($themeColors, 'buttonBg') !important;
    opacity: 0.24;
  }
  .mdc-slider__track--active_fill {
    border-color: map.get($themeColors, 'buttonBg') !important;
  }
}

mat-form-field.cusValidation mat-label {
  &::after {
    margin-left: 1px;
    margin-right: 0px;
    content: '*';
  }
  &.none::after {
    display: none;
  }
  &.error::after {
    color: map.get($themeColors, 'error');
  }
}
.isSmartDevice {
  :not(form) {
    .mat-mdc-select {
      .mat-mdc-select-value {
        letter-spacing: initial;
        font-size: calc(16px);
        color: map.get($themeColors, 'primaryColor');
        padding-right: unit-calc(10);
      }
    }
  }
}

.active-primary-bg.mat-mdc-option-active {
  background-color: map.get($themeColors, 'primaryColor3') !important;
  .option-lable {
    color: map.get($themeColors, 'white');
  }
  .option-value {
    color: map.get($themeColors, 'white');
  }
}

.cdk-program-focused .mat-sort-header-container {
  border-bottom: none !important;
}
