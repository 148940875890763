@use 'sass:map';
@import './assets/styles/bootstrap/bootstrap.scss';
@import './assets/styles/custom_materials';
@import './assets/styles/settings';
@import './assets/styles/dailogStyle';
@import './assets/styles/table';
@import './assets/styles/paginator';
@import './assets/styles/typography';
@import './assets/styles/buttons';

/* You can add global styles to this file, and also import other style files */


* {
  font-family: UniversForFiserv45, sans-serif !important; 
}

strong,
.bold,
b {
  font-family: UniversForFiserv55 !important;
}

html,
body {
  background-color: map.get($themeColors, 'greyBgColor') !important;
  color: map.get($themeColors, 'grey63');
  height: 100%;
  letter-spacing: initial;
}

.pt-color {
  color: map.get($themeColors, 'grey63');
  &.uploadText {
    @include font-weight('medium');
  }
}

.line-height-normal {
  line-height: normal;
}

.whiteBackground {
  background-color: map.get($themeColors, 'white');
}
a,
.textLinkColor {
  color: map.get($themeColors, 'primaryColor');
  font-size: unit-calc(16px);
  text-decoration: none;
  @include cursor;
  &:hover {
    text-decoration: underline;
  }
}
*:focus {
  outline: 0;
}
.icon100 {
  height: unit-calc(100);
  width: auto;
}
.w-16 {
  width: unit-calc(16px);
}
.w-20 {
  width: unit-calc(20px);
}
.w-24 {
  width: unit-calc(24px);
}
.width200 {
  width: unit-calc(200);
}
.width250 {
  width: unit-calc(250);
}
.h-20 {
  height: unit-calc(65px);
}
.fs14 {
  font-size: unit-calc(14px);
}
.p11 {
  padding: unit-calc(11px);
}
.p12 {
  padding: unit-calc(12px);
}
.t-50 {
  top: unit-calc(50px);
}

.l-2 {
  left: unit-calc(2px);
}

.pointer {
  @include cursor;
}

.opacity-50 {
  opacity: 0.5;
}

.positionRelative {
  position: relative;
}
.iconOnInput {
  position: absolute;
  right: unit-calc(15);
  top: unit-calc(7);
  opacity: 0.4;
}

.zindex-loader {
  z-index: 1020;
}

.textPrimary {
  color: map.get($themeColors, 'primaryColor');
}

.spanTextEllipsis {
  text-overflow: ellipsis;
  max-width: 80%;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}

.bgGrey {
  background: map.get($themeColors, 'bgGray');
}

.text-grey {
  color: map.get($themeColors, 'grey63');
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-value {
  color: map.get($themeColors, 'grey63');
}

.primary-color {
  color: map.get($themeColors, 'primaryColor') !important;
}

.primary-color-1 {
  color: map.get($themeColors, 'buttonBg') !important;
}
.bg-primary-color {
  background-color: map.get($themeColors, 'primaryColor') !important;
}

.text-black {
  color: map.get($themeColors, 'black') !important;
}

.text-color-white {
  color: map.get($themeColors, 'white');
}

.bg-color-white {
  background-color: map.get($themeColors, 'white');
}

h6 {
  font-size: unit-calc(20);
  font-weight: 200;
  &.small-text {
    font-size: unit-calc(16);
  }
}
h4 {
  font-size: unit-calc(14);
  font-weight: 200;
}

.mat-mdc-dialog-container {
  padding: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.mat-card-header-text {
  margin: 0 !important;
}

.card-title {
  font-size: unit-calc(16px);
  line-height: unit-calc(20px);
  @include font-weight('medium');
  color: map.get($themeColors, 'grey63');
}

.slider-pos {
  position: relative;
  top: unit-calc(-20px);
  left: unit-calc(-15px);
}
.slider-mb-10 {
  margin-bottom: unit-calc(100px);
}

.headerPipe {
  padding-right: unit-calc(20);
  position: relative;
  &::after {
    position: absolute;
    content: '';
    width: unit-calc(1);
    height: unit-calc(28);
    background: map.get($themeColors, 'greyccc');
    right: unit-calc(10);
    top: 0;
  }
}
.mobile-search {
  margin-left: unit-calc(-31px);
}
.angle-right {
  float: right;
}
.font-icon {
  font-size: unit-calc(20);
  &::before {
    color: map.get($themeColors, 'coolGrey8');
  }
}
.positionAbsolute {
  position: absolute;
}
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.mat-card-container {
  margin-bottom: unit-calc(16px);
  &.twoX {
    margin-bottom: unit-calc(32px);
  }
}
.mat-mdc-progress-spinner circle,
.mat-mdc-progress-spinner circle {
  stroke: map.get($themeColors, 'primaryColor');
}

.export-btn .mat-mdc-progress-spinner circle {
  stroke: map.get($themeColors, 'white');
}

.cdk-overlay-pane .mat-mdc-snack-bar-container {
  margin-top: unit-calc(80px);
}

.warning-message {
  color: map.get($themeColors, 'warning');
  font-size: unit-calc(13px);
} 
.error-message {
  color: map.get($themeColors, 'error');
  font-size: unit-calc(13px);
}
.success-message {
  color: map.get($themeColors, 'primaryColor');
  font-size: unit-calc(13px);
}
.error-txt {
  color: map.get($themeColors, 'error') !important;
}
.success-txt {
  color: map.get($themeColors, 'primaryColor') !important;
}

.apexcharts-xaxis-label,
.apexcharts-yaxis-label {
  color: map.get($themeColors, 'grey85') !important;
  font-size: unit-calc(14px) !important;
  font-weight: 300;
}

.align-center {
  width: unit-calc(50px);
  height: unit-calc(50px);
  position: absolute !important;
  top: calc(50%);
  left: calc(50%);
  transform: translate(-50%, -50%);
}

.referral-chart-tooltip-header {
  padding: unit-calc(4px);
  background-color: map.get($themeColors, 'grey6');
  border-bottom: unit-calc(1px) solid map.get($themeColors, 'greyccc');
  color: map.get($themeColors, 'primaryColor');
}

.referral-chart-tooltip-content {
  display: flex;
  align-items: center;
  height: unit-calc(30px);
}
.referral-chart-tooltip-content-label {
  font-size: unit-calc(12px);
  color: map.get($themeColors, 'grey888');
}
.referral-chart-tooltip-content-value {
  font-size: unit-calc(12px);
  color: map.get($themeColors, 'grey555');
  font-weight: bold;
  padding: unit-calc(5px);
}

.referral-chart-tooltip-content-icon {
  width: unit-calc(10px);
  height: unit-calc(10px);
  margin: unit-calc(5px);
  border-radius: unit-calc(12px);
}

.filterSection {
  background: map.get($themeColors, 'grey20');
}

modal-container.modal {
  top: 50%;
  margin-top: -20%;
}

html.cdk-global-scrollblock {
  position: sticky;
  overflow: hidden;
}

.rightSideBorder {
  box-shadow: 0 0.15rem 1.75rem 0
    rgba($color: map.get($themeColors, 'coolGrey10'), $alpha: 0.15);
}

.font-weight-normal {
  font-weight: normal;
}
.font-weight-thin {
  font-weight: 300;
}
.min-h-100 {
  min-height: unit-calc(100px);
}
.height672 {
  min-height: unit-calc(672);
}
.br_rad_tleft0 {
  border-top-left-radius: 0 !important;
}
.br_rad_tright0 {
  border-top-right-radius: 0 !important;
}

.br_rad_bleft0 {
  border-bottom-left-radius: 0 !important;
}
.br_rad_bright0 {
  border-bottom-right-radius: 0 !important;
}

.shadow {
  border-radius: 0.35rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}

.counter-text {
  font-size: unit-calc(30px);
  line-height: unit-calc(32px);
  color: map.get($themeColors, 'primaryColor');
}

hr {
  background-color: map.get($themeColors, 'coolGrey8');
}
.border {
  border-color: map.get($themeColors, 'coolGrey8');
}

.border-primary-1 {
  border: unit-calc(1px) solid map.get($themeColors, 'buttonBg') !important;
}
.border-error {
  border: unit-calc(1px) solid map.get($themeColors, 'error') !important;
}

.mat-mdc-menu-content:not(:empty) {
  padding: 0 !important;
}
.back-image-link {
  @include cursor;
  img {
    width: unit-calc(12px);
    // margin-right: unit-calc(10px);
    // margin-left: unit-calc(-25px);
  }
  color: map.get($themeColors, 'greyTextColor');
  &:hover {
    color: map.get($themeColors, 'greyTextColor');
  }
}
.mat-mdc-menu-panel {
  min-height: inherit !important;
  margin-top: unit-calc(10);
}

button.gm-ui-hover-effect {
  display: none !important;
}

.img-close {
  width: unit-calc(15px);
  height: unit-calc(15px);
}

.tagWrapper {
  background-color: rgba($color: #000000, $alpha: 0.04);
  margin-top: unit-calc(-7);
  div {
    padding: unit-calc(10);
    background: map.get($themeColors, 'white');
    border-radius: unit-calc(4);
    span {
      background-color: rgba($color: #000000, $alpha: 0.1);
    }
  }
}

.ls--1 {
  letter-spacing: unit-calc(-1px);
}

p {
  margin-bottom: 1rem !important;
}
.infoListTitle {
  @include font-weight('medium');
  font-size: unit-calc(12px);
  line-height: unit-calc(18px);
  color: map.get($themeColors, 'grey64');
  span {
    font-weight: normal;
  }
}
.infoListData {
  @include font-weight('normal');
  font-size: unit-calc(16px);
  line-height: unit-calc(20px);
  color: map.get($themeColors, 'grey63');
}
.tablistDetailsBgGrey {
  padding: unit-calc(10px 10px 0);
  background: map.get($themeColors, 'bgGray');
  border-radius: unit-calc(8px);
  font-size: unit-calc(14px);
  line-height: unit-calc(24px);
  color: map.get($themeColors, 'grey64');
  overflow: hidden;
  .col-md-6:first-child {
    margin-left: unit-calc(-10);
  }
  footer {
    border-top: unit-calc(1) solid map.get($themeColors, 'bootstrapBorder');
    background: map.get($themeColors, 'white');
    margin: unit-calc(0 -10);
  }
  .imageWrapper {
    padding-bottom: unit-calc(8);
    border-bottom: unit-calc(1) solid map.get($themeColors, 'bootstrapBorder');
    margin-bottom: unit-calc(8);
    text-align: center;
    img {
      width: unit-calc(280);
      margin: 0 auto;
    }
  }
}
.link {
  text-decoration: underline;
  color: map.get($themeColors, 'textLinkColor');
  cursor: pointer;
  font-size: unit-calc(16px);
  @include font-weight('normal');
  &.no-decoration {
    text-decoration: none !important;
  }
  &.no-pointer {
    cursor: default;
  }
}

.fileIcon {
  width: unit-calc(80);
  margin: auto;
}

.digital_icon_width {
  width: unit-calc(14);
}

.w-0 {
  width: 0;
  z-index: -1;
}

.w-150 {
  max-width: unit-calc(150px);
  width: unit-calc(150px);
}

//fix for safari icon issue
input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}

.warning-msg {
  color: map.get($themeColors, 'yellow02');
}

.sequencePausedWarning {
  background: map.get($themeColors, 'yellow01');
  color: map.get($themeColors, 'yellow02');
  display: flex;
  align-items: center;
  padding: unit-calc(10 10 8);
  border-radius: unit-calc(4);
  font-size: unit-calc(14);

  img {
    margin-right: unit-calc(5);
  }
}
@keyframes slideInFromBottomAll {
  0% {
    top: 100vh;
  }
  100% {
    top: 0;
  }
}

@keyframes slideInFromBottom0 {
  0% {
    top: unit-calc(-400px);
  }
  100% {
    top: 0;
  }
}

.create-lead-container-mobile {
  max-width: 100% !important;
  animation: 300ms ease-out 0s 1 slideInFromBottomAll;
  position: relative !important;
}

.footer-options-dialog {
  max-width: 100vw !important;
  position: absolute !important;
  bottom: 0 !important;
  .mat-mdc-dialog-surface {
    background-color: transparent !important;
  }
}

.bg-grey {
  background: map.get($themeColors, 'bgGray');
}
