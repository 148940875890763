@use 'sass:map';
@use 'sass:math';

$globalBaseSize: 16px !default;

// Breakpoints - TBD
$bpXSmall: '24em'; // 384px
$bpSmall: '32em'; // 512px
$bpMedium: '48em'; // 768px
$bpLarge: '62em'; // 980px
$bpXLarge: '75em'; // 1200px
$bpXXLarge: '93.75em'; // 1500px

// Brand Colors
$themeColors: (
  'primaryColor': #42ac2b,
  'primaryColor2': #43b02a,
  'primaryColor3': #208801,
  'info': #3271ab,
  'error': #dc3545,
  'error-bg': #fdf1f1,
  'bodyBg': #fcfcfc,
  'textLinkColor': #3271ab,
  'textLinkColor2': #1166bb,
  'gradientColor1': #b2eba4,
  'accountsMOABg':#FFE0CC,
  'accountMOATextColor':#FF6600,
  'gradientColor2': #008522,
  'errorBackground': #ffc7c0,
  'blue1': #2e8bc9,
  'moaContriBg': #c8dcef,
  'moaContriText': #324d69,
  'blue2': #5ad6ff52,
  'blue3': #2916f1d4,
  'purple1': #aa89ff,
  'leftNavBg': #202020,
  'headingColor': #344146,
  'leftNavColor': #ffffffbb,
  'leftNavColorHover': #eee,
  'leftNavColorBgHighlights': #333,
  'greyTextColor': #727272,
  'inputTextColor': #222,
  'greyBgColor': #f2f2f2,
  'buttonBg': #228800,
  'filterBg': #eafae7,
  'primayBg': #eaf5e6,
  'white': #fff,
  'coolGrey1': #fbfcfc,
  'fafafa': #fafafa,
  'coolGrey2': #f2f4f5,
  'coolGrey3': #eef1f3,
  'coolGrey4': #ebeef0,
  'coolGrey5': #e6eaed,
  'coolGrey6': #dde2e6,
  'coolGrey7': #b1b9bf,
  'coolGrey8': #8c8c8c,
  'coolGrey9': #44484c,
  'coolGrey10': #191919,
  'coolGrey11': #b1b1b1,
  'black': #000,
  'black1': #0000001a,
  'lightgrey1': #f8f9fa,
  'grey2': #fdfdfd,
  'grey3': #333,
  'grey4': #fefefe,
  'grey5': #f2f2f4,
  'grey6': #efefef,
  'grey7': #eaeaea,
  'grey9': #e8e8e8,
  'grey15': #e0e0e0,
  'grey18': #d1d1d1,
  'grey19': #cccccc2b,
  'grey20': #f7f7f7,
  'grey30': #6f6f6f,
  'grey301': #a9a9a9,
  'greybbb': #bbb,
  'greyccc': #ccc,
  'greyddd': #ddd,
  'grey555': #555,
  'grey666': #666,
  'grey888': #888,
  'grey200': #333333,
  'grey201': #f2f2f2,
  'grey202': #eafae7,
  'grey204': #d8d8d8,
  'green101': #ddffdd,
  'green102': #91c480,
  'yellow01': #ffee99,
  'yellow02': #997700,
  'grey36': #a3a3a3,
  'grey38': #858796,
  'grey45': #8c8c8c,
  'grey54': #757575,
  'grey63': #5a5a5a,
  'grey700': #adadad,
  'pink01': #553377,
  'pink02': #714F8E,
  'rose01': #ffddff,
  'rose02': #c63fc6,
  'grey64': #6d6d6d,
  'grey72': #474747,
  'grey81': #303030,
  'grey85': #242424,
  'grey90': #191919,
  'grey93': #1c1c1c,
  'grey94': #ced4da,
  'grey95': #edeff2ed,
  'bgGray': #f5f5f5,
  'lightPrimaryColor': #daf1da,
  'lightBlack': #606060,
  'green94': #eaf3e6,
  'green95': #a5ea97,
  'green96': #cceecc,
  'green97': #b5f2b5,
  'green98': #e5bb3b,
  'green99': #d5e8ce,
  'green100': #252,
  'warning': #b79c4b,
  'red': #dd2222,
  'red02': #be1f1f,
  'bootstrapBorder': #dee2e6,
  'bggreen1': #eaf4e6,
  'txtgreen1': #1b6d00,
  'bgblue1': #cfe0f1,
  'txtblue1': #0e5296,
  'txtblue2': #247bd2,
  'blue4': #d2e8f9,
  'bgyellow1': #feedbb,
  'txtyellow1': #655422,
  'bgred1': #fceded,
  'txtred1': #8c1616,
);

// Fonts
$fontStack: helvetica, arial;
$baseFontSize: unit-calc(16px);
$baseLineHeight: unit-calc(16px);

// Spacing
$spacing: $baseLineHeight;
$spacingTiny: round(math.div($spacing, 4));
$spacingSmall: round(math.div($spacing, 2));
$spacingMedium: round($spacing * 1.5);
$spacingLarge: round($spacing * 2);
$spacingXlarge: round($spacing * 4);

// z-index values
$zOverlay: 500;
$zDefault: 1;

//Default Opacity
$opacity: 0.9;
$overlayOpacity: 0.3;

// Animation
$easeOut: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeIn: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeInOut: cubic-bezier(0.645, 0.045, 0.355, 1);
$easeOutBack: cubic-bezier(0.12, 0.4, 0.29, 1.46);
$easeInBack: cubic-bezier(0.71, -0.46, 0.88, 0.6);
$easeInOutBack: cubic-bezier(0.71, -0.46, 0.29, 1.46);
$easeOutCirc: cubic-bezier(0.08, 0.82, 0.17, 1);
$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.34);
$easeInOutCirc: cubic-bezier(0.78, 0.14, 0.15, 0.86);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);

//padding
$overlayPadding: unit-calc(54px);
$overlayPaddingMobile: unit-calc(25px);

//shop vars
$rounded: unit-calc(25px);

$shapeCheck: 'M18.6 7c-0.6-0.6-1.5-0.6-2.1 0l-5.6 7.3L8.5 12c-0.6-0.6-1.5-0.6-2.1 0c-0.6 0.6-0.6 1.5 0 2.1l3.5 3.5 c0.6 0.6 1.5 0.6 2.1 0l6.5-8.5C19.2 8.6 19.2 7.6 18.6 7z';

$displayColor: map.get($themeColors, 'coolGrey10') !default;

$leftMenuWidth: 240px;
